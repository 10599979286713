import React, { Fragment, useState } from 'react'
import ImageSlider from '../ImageSlider'
import {
  createRoundedRange,
  extractTownAndStateFromAddress,
} from '../../Helpers/helpers'
import Map from '../Map/Map'

function RequestDetailsProvider({ request }) {
  const [showModal, setShowModal] = useState(false)
  const handleClick = async () => {
    setShowModal(true)
  }

  return (
    <Fragment>
      <div>
        <div
          className="shadow-md border-2 border-gray-400 rounded-md"
          onClick={handleClick}
        >
          <ImageSlider images={request.pictures} />
          <div className="bg-custom-grain p-2 flex flex-col">
            <div className="flex flex-row justify-between">
              <h1 className="text-[2.5vh] font-bold text-custom-maroon">
                {request.title}
              </h1>
              <button className="text-white bg-custom-maroon hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                Save Job
              </button>
            </div>
            <h2>
              <strong>Description: </strong>
              {request.description}
            </h2>
            <h2>
              <strong>Creation Date: </strong>{' '}
              {new Date(request.creationDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <h2>
              <strong>Service Category: </strong>
              {request.service}
            </h2>
            <h2>
              <strong>Desired Price Range: </strong>$
              {createRoundedRange(request.dollars)}
            </h2>
            <h2>
              <strong>Status: </strong>
              {request.status}
            </h2>
            <h2>
              <strong>Location: </strong>
              {extractTownAndStateFromAddress(request.address)}
            </h2>
          </div>
          <div>
            <Map address={request.address} isProvider={true} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default RequestDetailsProvider
